import React, { useContext } from 'react';
import { bool, string } from 'prop-types';
import { loadable } from 'nordic/lazy';
import RecommendationsIconFactory from './icon-factory';
import RecommendationsCarouselByType from '@recommendations-fe/carousel-by-type';
import StaticPropsContext from '../context/static-props';

const RecommendationsCardVertical = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardVertical,
});

RecommendationsCardVertical.preload();

const RecommendationsCardHorizontalTitleFirst = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardHorizontalTitleFirst,
});

RecommendationsCardHorizontalTitleFirst.preload();

const RecommendationsListWithoutContainer = loadable(() => import('./recomendations-list'), {
  resolveComponent: components => components.RecommendationsListWithoutContainer,
});

RecommendationsListWithoutContainer.preload();

const RecommendationsListGrid = loadable(() => import('./recomendations-list'), {
  resolveComponent: components => components.RecommendationsListGrid,
});

RecommendationsListGrid.preload();

const RecommendationsListDouble = loadable(() => import('./recomendations-list'), {
  resolveComponent: components => components.RecommendationsListDouble,
});

RecommendationsListDouble.preload();

const RecommendationsCarouselFreeWithoutContainer = loadable(() => import('./recomendations-carousel-free'), {
  resolveComponent: components => components.RecommendationsCarouselFreeWithoutContainer,
});

RecommendationsCarouselFreeWithoutContainer.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const { deviceType } = useContext(StaticPropsContext);
  return (
    <RecommendationsCarouselByType
      carousels={{
        grid: propsGrid => (
          <RecommendationsListWithoutContainer
            ListComponent={RecommendationsListGrid}
            listType="grid"
            Card={RecommendationsCardVertical}
            {...propsGrid}
          />
        ),
        vertical: propsVertical => (
          <RecommendationsListWithoutContainer
            ListComponent={RecommendationsListDouble}
            listType="double"
            Card={RecommendationsCardHorizontalTitleFirst}
            {...propsVertical}
          />
        ),
        horizontal: propsHorizontal => (
          <RecommendationsCarouselFreeWithoutContainer
            Card={RecommendationsCardVertical}
            paddingHorizontal={16}
            {...propsHorizontal}
          />
        ),
        default: propsDefault => (
          <RecommendationsCarouselFreeWithoutContainer
            Card={RecommendationsCardVertical}
            paddingHorizontal={16}
            {...propsDefault}
          />
        ),
      }}
      IconFactory={RecommendationsIconFactory}
      contextProps={{
        thousandSeparator,
        decimalSeparator,
      }}
      isPolycard={isPolycard}
      deviceType={deviceType}
      {...props}
      cardProps={{
        polyClassName: 'recos-polycard',
      }}
    />
  );
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
};

export default Recommendations;
